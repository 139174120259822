import { Preference, PreferenceRating } from '@/gql/graphql';

export const convertPreferenceScoreMap = (
  preferences: Preference[],
): {
  developmentStyle: PreferenceRating;
  productMarketOrientation: PreferenceRating;
  productPhaseOrientation: PreferenceRating;
  companyScaleOrientation: PreferenceRating;
} => {
  const res = {
    developmentStyle: PreferenceRating.Medium,
    productMarketOrientation: PreferenceRating.Medium,
    productPhaseOrientation: PreferenceRating.Medium,
    companyScaleOrientation: PreferenceRating.Medium,
  };

  preferences.forEach((preference) => {
    switch (preference.category) {
      case 'DEVELOPMENT_STYLE_TEAM':
        res.developmentStyle = preference.score;
        break;
      case 'MARKET_ORIENTATION_TOB':
        res.productMarketOrientation = preference.score;
        break;
      case 'PRODUCT_PHASE_ONE_TO_TEN':
        res.productPhaseOrientation = preference.score;
        break;
      case 'COMPANY_SIZE_ENTERPRISE':
        res.companyScaleOrientation = preference.score;
        break;
      default:
        break;
    }
  });

  return res;
};

export const convertPreferenceScore = (score: PreferenceRating): number => {
  switch (score) {
    case PreferenceRating.VeryLow:
      return 1;
    case PreferenceRating.Low:
      return 2;
    case PreferenceRating.Medium:
      return 3;
    case PreferenceRating.High:
      return 4;
    case PreferenceRating.VeryHigh:
      return 5;
    default:
      return 3;
  }
};
