import React from 'react';
import { MdCheck } from 'react-icons/md';

type Props = {
  rating: number;
};

export const RatingStepper = ({ rating }: Props) => {
  const totalSteps = 5;

  return (
    <div className="flex w-full items-center justify-between gap-2">
      {[...Array(totalSteps)].map((_, index) => {
        const stepNumber = index + 1;
        const isCompleted = stepNumber === rating;
        return (
          <>
            {index > 0 && <div className="bg-shade-medium-default h-[1px] w-full"></div>}
            <div
              className={`flex shrink-0 items-center justify-center rounded-full ${
                isCompleted ? 'bg-primary-dark-default h-5 w-5' : 'bg-shade-medium-default h-2 w-2'
              }`}
            >
              {isCompleted && <MdCheck className="icon-shade-white-default h-4 w-4" />}
            </div>
          </>
        );
      })}
    </div>
  );
};
