import { MdArrowBack, MdArrowForward } from 'react-icons/md';
import { Text } from '@4design/for-ui';
import { PairBadge } from '@/components/ui-parts/badge';
import { LabeledDList } from '@/components/ui-parts/dllist';
import { FieldContainer } from '@/components/ui-parts/FieldContainer';
import { HyperLink } from '@/components/ui-parts/Link';
import { RatingStepper } from '@/components/ui-parts/rating-stepper';
import { MasterEngineerSkill, Talent } from '@/gql/graphql';
import { formatDate } from '@/lib/dateformat';
import { ENGAGEMENT_INTENT_LABEL } from '../../../../constants/talent/talentProfile';
import { convertPreferenceScore, convertPreferenceScoreMap } from '../../constants/talent-profile';

type Props = {
  talent: Talent;
};

export const TalentProfile = (props: Props) => {
  const categorySKills = props.talent.talentProfile.talentProfileMasterEngineerSkills.reduce(
    (acc, curr) => {
      if (!acc[curr.category.id]) {
        acc[curr.category.id] = [];
      }

      acc[curr.category.id].push(curr);
      return acc;
    },
    {
      language: [] as MasterEngineerSkill[],
      framework: [] as MasterEngineerSkill[],
      middleware: [] as MasterEngineerSkill[],
      infra: [] as MasterEngineerSkill[],
    } as Record<string, MasterEngineerSkill[]>,
  );

  const score = convertPreferenceScoreMap(props.talent.talentProfile.talentProfilePreferences);

  return (
    <div className="flex flex-col gap-4">
      <FieldContainer>
        <Text size="xr" weight="bold">
          基本情報
        </Text>
        {props.talent.talentProfile.imageSrc && (
          <img src={props.talent.talentProfile.imageSrc} alt={props.talent.name} className="h-24 w-24 rounded-full" />
        )}
        <LabeledDList
          details={[
            {
              label: '名前',
              content: (
                <span className="flex flex-col">
                  <Text size="s">{props.talent.nameKana}</Text>
                  <Text>{props.talent.name}</Text>
                </span>
              ),
            },
            // {
            //   label: '生年月日',
            //   content: (
            //     <Text>
            //       {formatDate(new Date(person.birthDay), 'yyy/MM/dd')}（
            //       {differenceInYears(new Date(), new Date(person.birthDay))}）
            //     </Text>
            //   ),
            // },
            // {
            //   label: '電話番号',
            //   content: <Text>{props.talent.tel}</Text>,
            // },
            // {
            //   label: '住所',
            //   content: (
            //     <span className="flex flex-col">
            //       <Text>{person.zipCode}</Text>
            //       <Text>{person.address}</Text>
            //     </span>
            //   ),
            // },
          ]}
        />
        <Text size="xr" weight="bold">
          SNS
        </Text>
        <LabeledDList
          details={[
            {
              label: 'X (旧Twitter)',
              content: props.talent.talentProfile.twitter ? (
                <HyperLink
                  label={`https://x.com/${props.talent.talentProfile.twitter}`}
                  underline
                  size="r"
                  to={props.talent.talentProfile.twitter}
                  target="_blank"
                />
              ) : null,
            },
            {
              label: 'GitHub',
              content: props.talent.talentProfile.github ? (
                <HyperLink
                  label={`https://github.com/${props.talent.talentProfile.github}`}
                  underline
                  size="r"
                  to={props.talent.talentProfile.github}
                  target="_blank"
                />
              ) : null,
            },
            {
              label: 'Zenn',
              content: props.talent.talentProfile.zenn ? (
                <HyperLink
                  label={`https://zenn.dev/${props.talent.talentProfile.zenn}`}
                  underline
                  size="r"
                  to={props.talent.talentProfile.zenn}
                  target="_blank"
                />
              ) : null,
            },
            {
              label: 'Qiita',
              content: props.talent.talentProfile.qiita ? (
                <HyperLink
                  label={`https://qiita.com/${props.talent.talentProfile.qiita}`}
                  underline
                  size="r"
                  to={props.talent.talentProfile.qiita}
                  target="_blank"
                />
              ) : null,
            },
          ]}
        />
      </FieldContainer>
      <FieldContainer>
        <Text size="xr" weight="bold">
          希望条件
        </Text>
        <LabeledDList
          details={[
            {
              label: '希望時給',
              content: (
                <Text>
                  {props.talent.talentProfile.priceHourlyMin?.toLocaleString() || '-'} -{' '}
                  {props.talent.talentProfile.priceHourlyMax?.toLocaleString() || '-'} 円 / 時
                </Text>
              ),
            },
            {
              label: '希望稼働日数 (週)',
              content: (
                <Text>
                  {props.talent.talentProfile.weeklyWorkingDayMin?.toLocaleString() || '-'} -{' '}
                  {props.talent.talentProfile.weeklyWorkingDayMax?.toLocaleString() || '-'} 日 / 週
                </Text>
              ),
            },
            {
              label: '案件意欲',
              content: (
                <Text>
                  {props.talent.talentProfile.engagementIntent
                    ? ENGAGEMENT_INTENT_LABEL[props.talent.talentProfile.engagementIntent]
                    : '-'}
                </Text>
              ),
            },
            {
              label: '自己PR',
              content: <Text className="whitespace-pre-wrap">{props.talent.talentProfile.selfPromotion || '-'}</Text>,
            },
            {
              label: '開発スタイルの志向性',
              content: (
                <RatingDetail
                  score={convertPreferenceScore(score.developmentStyle)}
                  firstLabel="個人志向が高い"
                  lastLabel="チーム志向が高い"
                  detail={{
                    first: {
                      title: '個人志向の例',
                      description: '・個人の裁量やスケジュール感で開発したい',
                    },
                    last: {
                      title: 'チーム志向の例',
                      description: '・チーム内でコミュニケーションを取りながら開発したい',
                    },
                  }}
                />
              ),
            },
            {
              label: 'プロダクトの市場志向',
              content: (
                <RatingDetail
                  score={convertPreferenceScore(score.productMarketOrientation)}
                  firstLabel="toC志向が高い"
                  lastLabel="toB志向が高い"
                  detail={{
                    first: {
                      title: 'toC志向の例',
                      description: '・個人顧客向けのプロダクト開発に関わりたい',
                    },
                    last: {
                      title: 'toB志向の例',
                      description: '・法人顧客向けプロダクト開発に関わりたい',
                    },
                  }}
                />
              ),
            },
            {
              label: 'プロダクトのフェーズ志向',
              content: (
                <RatingDetail
                  score={convertPreferenceScore(score.productPhaseOrientation)}
                  firstLabel="0→1志向が高い"
                  lastLabel="1→10志向が高い"
                  detail={{
                    first: {
                      title: '0→1志向の例',
                      description: '・PoCやMVP等、初期フェーズのプロジェクトに関わりたい',
                    },
                    last: {
                      title: '1→10志向の例',
                      description: '・PMF後等、機能拡充やパフォーマンス改善等のプロジェクトに関わりたい',
                    },
                  }}
                />
              ),
            },
            {
              label: '企業の規模志向',
              content: (
                <RatingDetail
                  score={convertPreferenceScore(score.productPhaseOrientation)}
                  firstLabel="スタートアップ・ベンチャー志向が高い"
                  lastLabel="中堅・大手志向が高い"
                  detail={{
                    first: {
                      title: 'スタートアップ・ベンチャー志向の例',
                      description: '・幅広い業務・素早い方向転換が可能な組織体制のプロジェクトに関わりたい',
                    },
                    last: {
                      title: '中堅・大手志向の例',
                      description: '・方向性が定まり業務が整理されていて集中できる組織体制のプロジェクトに関わりたい',
                    },
                  }}
                />
              ),
            },
          ]}
        />
      </FieldContainer>
      <FieldContainer>
        <Text size="xr" weight="bold">
          技術スタック
        </Text>
        <Text weight="bold" size="r">
          経験職種
        </Text>
        <div className="flex flex-wrap gap-2">
          {props.talent.talentProfile.talentProfileMasterEngineerPositions.map((position) => (
            <PairBadge key={position.id} primaryLabel={position.name} secondaryLabel="0" />
          ))}
        </div>
        <Text weight="bold" size="r">
          言語/フレームワーク
        </Text>
        <div className="flex flex-wrap gap-2">
          {[...categorySKills['language'], ...categorySKills['framework']].map((item) => {
            return <PairBadge key={item.id} primaryLabel={item.name} secondaryLabel="0" />;
          })}
        </div>
        <Text weight="bold" size="r">
          ミドルウェア
        </Text>
        <div className="flex flex-wrap gap-2">
          {categorySKills['middleware'].map((item) => {
            return <PairBadge key={item.id} primaryLabel={item.name} secondaryLabel="0" />;
          })}
        </div>
        <Text weight="bold" size="r">
          インフラ
        </Text>
        <div className="flex flex-wrap gap-2">
          {categorySKills['infra'].map((item) => {
            return <PairBadge key={item.id} primaryLabel={item.name} secondaryLabel="0" />;
          })}
        </div>
      </FieldContainer>
      <FieldContainer>
        <Text size="xr" weight="bold">
          履歴書
        </Text>
        <Text size="r" weight="bold">
          職務経歴書
        </Text>
        {props.talent.talentProfile.talentProfileWorkHistories.map((item, i) => {
          return (
            <FieldContainer key={i}>
              <Text size="xr" weight="bold">
                {item.companyName}
              </Text>
              <LabeledDList
                details={[
                  {
                    label: '期間',
                    content: (
                      <Text size="r">
                        {formatDate(item.periodFrom)} - {formatDate(item.periodTo)}
                      </Text>
                    ),
                  },
                  {
                    label: '職種',
                    content: (
                      <Text size="r">
                        {item.talentProfileWorkHistoryMasterEngineerPositions.length > 0
                          ? item.talentProfileWorkHistoryMasterEngineerPositions.map((v) => v.name).join(' / ')
                          : '-'}
                      </Text>
                    ),
                  },
                  {
                    label: '業務内容',
                    content: <Text size="r">{item.jobDescription || '-'}</Text>,
                  },
                ]}
              />
            </FieldContainer>
          );
        })}
      </FieldContainer>
    </div>
  );
};

type RatingDetailProps = {
  score: number;
  firstLabel: string;
  lastLabel: string;
  detail: {
    first: {
      title: string;
      description: string;
    };
    last: {
      title: string;
      description: string;
    };
  };
};

const RatingDetail = ({ score, firstLabel, lastLabel, detail }: RatingDetailProps) => {
  return (
    <div className="flex h-full w-full items-center gap-4 py-2">
      <div className="flex w-1/2 flex-col gap-1">
        <RatingStepper rating={score} />
        <div className="flex justify-between">
          <div className="flex items-center gap-0.5">
            <MdArrowBack className="icon-shade-light-default" />
            <Text className="text-shade-medium-default">{firstLabel}</Text>
          </div>
          <div className="flex items-center gap-0.5">
            <Text className="text-shade-medium-default">{lastLabel}</Text>
            <MdArrowForward className="icon-shade-light-default" />
          </div>
        </div>
      </div>
      <div className="bg-shade-medium-default h-full w-[1px] grow"></div>
      <div className="flex w-1/2 flex-col">
        <Text className="text-shade-medium-default" weight="bold">
          {detail.first.title}
        </Text>
        <Text className="text-shade-medium-default">{detail.first.description}</Text>
        <Text className="text-shade-medium-default" weight="bold">
          {detail.last.title}
        </Text>
        <Text className="text-shade-medium-default">{detail.last.description}</Text>
      </div>
    </div>
  );
};
